import React from 'react'
import { handleAuthentication } from '../../../utils/auth'

const Callback = () => {
  const url = process.env.GATSBY_STRAPI_API_URL || 'http://localhost:1337'
  handleAuthentication(url, 'google')

  return (
    <div className="container section has-text-centered">
      <div className="columns is-centered">
        <div className="column is-half">
          <h1 className="is-size-5">جاري تسجيل الدخول ...</h1>
          <progress className="progress is-medium is-info" max="100">
            60%
          </progress>
        </div>
      </div>
    </div>
  )
}

export default Callback
